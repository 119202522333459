import React from 'react';

const LazyRodeoEvent = React.lazy(() =>
  import('./autocart')
);

const Autocart = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className='transition-loader'><img src='/images/bull-loader.gif' /></div>}>
    <LazyRodeoEvent {...props} />
  </React.Suspense>
);

export default Autocart