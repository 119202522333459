let apiBaseUrl = "";
let socketUrl = "";
let aiBaseUrl = "";
let siteUrl = "";
let appStage = process.env.REACT_APP_STAGE;

if (appStage === "local") {
  siteUrl = "http://localhost:3030";
  apiBaseUrl = "http://10.25.27.57:8002/api/v1/";
  //apiBaseUrl = "https://pfr-backend.glohtesting.com/api/v1/";
  // apiBaseUrl = "https://controlcenter.profantasyrodeo.com/api/v1/";
  aiBaseUrl = "https://aiapi.profantasyrodeo.com/api/v1/";
} else if (appStage === "dev") {
  siteUrl = "https://pfr.glohtesting.com";
  apiBaseUrl = "https://pfr-backend.glohtesting.com/api/v1/";
  aiBaseUrl = "https://aiapi.profantasyrodeo.com/api/v1/";
} else if (appStage === "prod") {
  siteUrl = "https://www.profantasyrodeo.com";
  apiBaseUrl = "https://controlcenter.profantasyrodeo.com/api/v1/";
  aiBaseUrl = "https://aiapi.profantasyrodeo.com/api/v1/";
}

export const API_BASE_URL = apiBaseUrl;
export const SOCKETURL = socketUrl;
export const AI_BASE_URL = aiBaseUrl;
export const SITE_URL = siteUrl;
