import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StateExtended } from '../../interfaces/StateExtended'

export function useMenuListSelector() {
    const menuList = useSelector((state: StateExtended) => state.event.getMenuList)
    return menuList;
}
export function useSettingsSelector() {
    const settings = useSelector((state: StateExtended) => state.event.settings)
    return settings;
}
export function useSponsorSelector() {
    const sponsor = useSelector((state: StateExtended) => state.event.sponsor)
    return sponsor;
}
export function useBlocksSelector() {
    const blocks = useSelector((state: StateExtended) => state.event.blocksData)
    return blocks;
}
export function useGlobalTeamStatusSelector() {
    const teamStatus = useSelector((state: StateExtended) => state.global.teamCreateProcessStatus)
    return teamStatus;
}
export function useSEOSelector() {
    const seo_list = useSelector((state: StateExtended) => state.global.seo_list)
    return seo_list;
}
export function useReffererUrlSelector() {
    const referrer_url = useSelector((state: StateExtended) => state.global.referrer_url)
    return referrer_url;
}
export function UseFanMagazineSelector() {
    const referrer_url = useSelector((state: StateExtended) => state.event.fanMagazine)
    return referrer_url;
}